const webfont = () => {
	window.WebFontConfig = {
		google: {
			families: [
				'Noto+Sans+JP:400,500,700',
				'Noto+Serif+JP:600',
				'Montserrat:600,700',
			],
		},
		active: () => {
			sessionStorage.fonts = true;
		},
	};
	(() => {
		let wf = document.createElement('script');
		wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
		wf.type = 'text/javascript';
		wf.async = 'true';
		let s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(wf, s);
	})();
};

/* グローバルナビ
---------------------------------------------------------- */
const gnavAction = () => {
	const body = document.body;
	const gnavOpenClass = 'is-nav-opened';
	const gnav = document.getElementById('gnav');

	gnav.scrollTop = 1;

	window.addEventListener(
		'touchmove',
		(event) => {
			if (body.classList.contains(gnavOpenClass) && event.target === gnav) {
				event.preventDefault();
			} else {
				event.stopPropagation();
			}
		},
		{ passive: false }
	);

	if (body.classList.contains(gnavOpenClass)) {
		body.classList.remove(gnavOpenClass);
	} else {
		body.classList.add(gnavOpenClass);
	}
};

const gnavToggle = () => {
	const gnavToggle = document.querySelectorAll('.js-gnav-toggle');
	const overlay = document.getElementById('overlay');

	// ハンバーガーメニュークリック
	if (gnavToggle !== null) {
		Array.prototype.forEach.call(gnavToggle, (el) => {
			el.addEventListener('click', (e) => {
				gnavAction(e);
			});
		});
	}

	// 背景クリック
	if (overlay !== null) {
		overlay.addEventListener('click', (e) => {
			gnavAction(e);
		});
	}
};

/* タブ
---------------------------------------------------------- */
const tab = () => {
	const tab = document.querySelectorAll('.js-tab');

	if (tab.length < 1) return false;

	Array.prototype.forEach.call(tab, (el, i) => {
		const tab_head = el.querySelectorAll('.js-tab-head');
		const tab_head_child = tab_head[0].children;
		const tab_body = el.querySelectorAll('.js-tab-body');
		const tab_body_child = tab_body[0].children;

		tab_body_child[0].classList.add('is-active');
		tab_head_child[0].classList.add('is-active');

		for (let $i = 0; $i < tab_head_child.length; $i++) {
			let index = $i;
			tab_head_child[$i].addEventListener('click', () => {
				for (let $i = 0; $i < tab_head_child.length; $i++) {
					tab_body_child[$i].classList.remove('is-active');
					tab_head_child[$i].classList.remove('is-active');
				}

				tab_body_child[index].classList.add('is-active');
				tab_head_child[index].classList.add('is-active');
			});
		}
	});
};

/* ドロップダウン
---------------------------------------------------------- */
const dropdown = () => {
	const button = document.querySelectorAll('.js-dropdown-toggle');
	const close = document.querySelectorAll('.js-dropdown-close');
	const openClass = 'is-expand';

	Array.prototype.forEach.call(button, (el) => {
		el.addEventListener('click', (e) => {
			e.preventDefault();
			el.parentNode.classList.toggle(openClass);
		});
	});

	Array.prototype.forEach.call(close, (el) => {
		el.addEventListener('click', (e) => {
			e.preventDefault();
			el.parentNode.classList.remove(openClass);
		});
	});
};

/* スクロール関連
---------------------------------------------------------- */
const scrolleEvents = () => {
	const body = document.body;
	const scrolledClass = 'is-scrolled';
	const downScrolledClass = 'is-down-scrolled';
	// const progressBar = document.querySelector('.progress__bar');
	// const wrap = document.querySelector('.wrap');
	let startPos = 0;

	const scrolledAction = () => {
		const windowYPos = window.pageYOffset;
		// const height = wrap.scrollHeight - document.documentElement.clientHeight;
		// const scrolled = windowYPos / height;
		const scrollTop = Math.max(
			windowYPos,
			document.documentElement.scrollTop,
			document.body.scrollTop
		);

		let currentPos = scrollTop;

		// 下方向へのスクロール
		if (currentPos > startPos) {
			body.classList.add(downScrolledClass);
			body.classList.remove('is-scroll-disabled');
		} else {
			body.classList.remove(downScrolledClass);
		}
		startPos = currentPos;

		// スクロール判定
		if (scrollTop > 1) {
			body.classList.add(scrolledClass);
		} else {
			body.classList.remove(scrolledClass);
		}
	};

	window.addEventListener('load', scrolledAction);
	window.addEventListener('scroll', scrolledAction);
	window.addEventListener('touchmove', scrolledAction);
};

const smoothScroll = () => {

  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
    for (let i = 0; i < smoothScrollTrigger.length; i++){
      smoothScrollTrigger[i].addEventListener('click', (e) => {
        e.preventDefault();
        let href = smoothScrollTrigger[i].getAttribute('href');
        let targetElement = document.getElementById(href.replace('#', ''));
        const rect = targetElement.getBoundingClientRect().top;
        const offset = window.pageYOffset;
        const gap = 0;
        const target = rect + offset - gap;
        window.scrollTo({
          top: target,
          behavior: 'smooth',
        });
      });
    }

};

const disableScroll = (el) => {

	const body = document.body;
	const className = 'is-scroll-disabled';
	
	// el.scrollTop = 1;

	window.addEventListener('touchmove', (event) =>  {
	  if( body.classList.contains(className) && event.target === el ){
	    event.preventDefault();
	  } else {
	    event.stopPropagation();
	  }
	},{ passive: false });

	// if( body.classList.contains(className) ){
	// 	body.classList.remove(className);
	// } else {
	// 	body.classList.add(className);
	// }

};

/* 要素のフェードイン
---------------------------------------------------------- */
const inView = () => {
	const items = document.querySelectorAll('.js-inview');
	const isInview = 'is-inview';

	if (items.length < 1) return false;

	const cb = (entries, observer) => {
		entries.forEach((entry) => {

			const toggle = entry.target.dataset.inviewToggle;

			// console.log(entry);

			if (entry.isIntersecting) {

				entry.target.classList.add(isInview);

			} else {

				if ( toggle ) {
					entry.target.classList.remove(isInview)
				}

			}

		});
	};

	const options = {
		root: null,
		rootMargin: '0px 0px 0px 0px',
		threshold: [0.1]
	};

	const io = new IntersectionObserver(cb, options);

	items.forEach((item) => {
		io.observe(item);
	});
};

/* GSAP
---------------------------------------------------------- */
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const intro = () => {

	const body = document.body;
	const intro = document.getElementById('intro'); 
	const video = document.getElementById('intro-video');
	const about = document.getElementById('intro-about');
	const leads = document.querySelectorAll('.js-intro-lead');

	if ( !body.classList.contains('home-page') ) return false;

	gsap.to(intro, {
		scrollTrigger: {
			trigger: intro,
			start: "top top",
			end: "bottom 50%",
			toggleClass: { targets: body, className: 'is-intro-viewed' },
			// markers: true,
		}
	});

	window.addEventListener('load', () => {
		setTimeout(() => {
			if ( isPc() && body.classList.contains('is-intro-viewed') ) {
				window.scrollTo(0, 0);
				body.classList.add('is-scroll-disabled');
			}
		}, 200)
	});

	let tl = gsap.timeline({
		scrollTrigger: {
			trigger: about,
			start: 'top bottom',
			end: "top 50%",
			scrub: true,
			// markers: true,
		}
	});

	tl.to(video, {
    width: 'calc(100% - 590px)',
  });

	leads[0].addEventListener('transitionstart', (e) => {
		if ( !body.classList.contains('is-scrolled') ) {
			body.classList.add('is-scroll-disabled');
		}
	});

	leads.forEach((lead, i) => {

		lead.addEventListener('transitionend', (e) => {
			if ( e.propertyName === 'opacity' ) {
				body.classList.add(`is-scene-0${i}`);
			}

			if ( lead.classList.contains('intro-lead-03') ) {
				body.classList.remove('is-scroll-disabled');
			}
		});
	});

};

const parallax = () => {

	const els = document.querySelectorAll('.js-parallax');

	els.forEach((el) => {
		const y = el.dataset.y || -100;
		gsap.fromTo(el.querySelector('.js-parallax__item'), {
			y: 0,
		}, {
			y: y,
			ease: "none",
			scrollTrigger: {
			trigger: el,
			start: "top bottom",
			end: "bottom top",
			scrub: 1,
			// markers: true,
			}
		}); 
	});

};

const invert = () => {

	const content = document.querySelector('.js-invert');
	const contentH = content.scrollHeight / 2;
	const els = gsap.utils.toArray('.js-invert-toggle');

	els.forEach(el => {

		const sp = el.dataset.invertSp;
		const position = el.dataset.invert;
		const addClass = ( position ) ? `is-invert--${position}` : 'is-invert';

		gsap.to(el, {
			scrollTrigger: {
				trigger: el,
				start: `top bottom-=10%`,
				end: `bottom bottom-=10%`,
				onEnter: () => {
					if ( sp ) {
						if ( !isPc() ) {
							content.classList.add(addClass);
						}
					} else {
						content.classList.add(addClass);
					}
				},
				onEnterBack: () => {
					if ( sp ) {
						if ( !isPc() ) {
							content.classList.add(addClass);
						}
					} else {
						content.classList.add(addClass);
					}
				},
				onLeave: () => {
					content.classList.remove(addClass);
				},
				onLeaveBack: () => {
					content.classList.remove(addClass);
				},
				// toggleClass: { targets: header, className: 'is-invert' },
				// markers: true,
			}
		});
	});

}

const scrollToggleClass = () => {

	const els = gsap.utils.toArray('.js-scroll-toggle-class');

	els.forEach(el => {

		const className = el.dataset.className;
		const start = ( el.dataset.start ) ? el.dataset.start : 'top top';

		gsap.to(el, {
			scrollTrigger: {
				trigger: el,
				start: start,
				endTrigger: 'html',
				toggleClass: { targets: el, className: className },
				// markers: true,
			}
		});
	});

};

const stickyUnder = () => {

	const body = document.body;
	const stickyUnder = gsap.utils.toArray('.js-sticky-under');
	stickyUnder.forEach(el => {

		// const inner = el.querySelector('.sticky-under__inner');
		el.style.setProperty('--height', `${el.scrollHeight}px`);

		gsap.to(el, {
			scrollTrigger: {
				trigger: el.previousElementSibling,
				start: `top ${body.getBoundingClientRect().top}`,
				end: `bottom top`,
				toggleClass: { targets: el, className: 'is-fixed' },
				// markers: true,
				// onLeave: () => {
				// 	stInView(el)
				// },
			}
		});
	});

};

const scrollPanels = () => {

	const body = document.body;
	const pagination = document.querySelectorAll('.js-work-pagination');

	pagination.forEach((el, i) => {
		el.addEventListener('click', (e) => {
			const href = document.querySelector(`#${el.dataset.href}`);
			goToSection(href)
			e.preventDefault();
		});
	});

	const scrolling = {
		enabled: true,
		events: 'scroll,wheel,touchmove,pointermove'.split(','),
		prevent: (e) => e.preventDefault(),
		disable() {
			if (scrolling.enabled) {
				scrolling.enabled = false;
				window.addEventListener('scroll', gsap.ticker.tick, { passive: true });
				scrolling.events.forEach((e, i) =>
					(i ? document : window).addEventListener(e, scrolling.prevent, {
						passive: false,
					})
				);
			}
		},
		enable() {
			if (!scrolling.enabled) {
				scrolling.enabled = true;
				window.removeEventListener('scroll', gsap.ticker.tick);
				scrolling.events.forEach((e, i) =>
					(i ? document : window).removeEventListener(e, scrolling.prevent)
				);
			}
		},
	};
	
	const goToSection = (el, e, anim, i) => {
		
		const duration = ( el === '#intro-about' ) ? 3 : 1;

		if (scrolling.enabled) {
			// skip if a scroll tween is in progress
			scrolling.disable();
			body.classList.remove('is-scrolling-end');
			gsap.to(window, {
				scrollTo: { y: el, autoKill: false },
				onComplete: () => {
					scrolling.enable();
					body.classList.add('is-scrolling-end');
				},
				duration: duration,
			});
	
			anim && anim.restart();
		}
	};

	if ( body.classList.contains('home-page') ) {

		ScrollTrigger.create({
			trigger: '.js-intro-content',
			start: `top bottom-=1`,
			end: `bottom top+=1`,
			onEnter: () => {
				if ( !isPc() ) return false;
				if ( document.documentElement.scrollTop < 10 ) {
					goToSection('#intro-about')
				}
			},
			// markers: true,
		});

	}

	if ( body.classList.contains('recruit-page') ) {

		const els = document.querySelectorAll('.js-scroll-panels');
		const margin = ( isPc() ) ? 100 : 50;

		els.forEach((el, i) => {
			ScrollTrigger.create({
				trigger: el,
				start: `top bottom-=${margin}`,
				end: `bottom top+=${margin}`,
				// endTrigger: endTrigger,
				onEnter: (e) => {
					if ( i === 0 ) return false;
					goToSection(el)
				},
				onEnterBack: (e) => {
					if ( i === els.length - 1 ) return false;
					goToSection(el)
				},
				toggleClass: { targets: body, className: `is-panels-view-${i+1}` },
				// markers: true,
			});
		});

		ScrollTrigger.create({
			trigger: '.js-work-stage',
			start: "top-=100 top-=100",
			end: "bottom bottom",
			onEnter: () => {
				body.classList.add('is-work-stage-start');
			},
			onLeaveBack: () => {
				body.classList.remove('is-work-stage-start');
			},
			onEnterBack: () => {
				body.classList.remove('is-work-stage-end');
			},
			onLeave: () => {
				body.classList.add('is-work-stage-end');
			},
			toggleClass: { targets: body, className: `is-work-stage` },
			// markers: true,
		});

	}

};

const headerH = () => {
	return document.querySelector('.header').offsetHeight;
};

//* cursorFollow
//---------------------------------------------------------- */
const cursorFollow = () => {

	const body = document.body;

	if ( !body.classList.contains('home-page') ) return false;

	const container = document.querySelector('.js-cursor-follow');
	const details = container.querySelectorAll('.business-details')
	const item = container.querySelector('.js-cursor-follow__item');

	container.addEventListener('mousemove', (e) => {itemPosition(e)});
	container.addEventListener('mouseenter', (e) => {itemPosition(e)});

	function itemPosition(e) {
		item.style.left = e.clientX - 80 + "px";
		item.style.top = e.clientY - 80 + "px";
	};

	details.forEach((detail, i) => {

		const className = `is-mouseenter-detail-${i}`;

		detail.addEventListener('mouseenter', (e) => {
			body.classList.add(className);
		});
		detail.addEventListener('mouseleave', (e) => {
			body.classList.remove(className);
		});
	});

};

//* slider
//---------------------------------------------------------- */
const slider = () => {

	const pagination = document.querySelector('.js-slider-pagination');
	const buttons = document.querySelectorAll('.js-slider-pagination__button');

	if ( !pagination ) return false;

	// buttons.forEach((el, i) => {
	// 	el.addEventListener('click', (e) => {
	// 		sliders(i - 1);
	// 		e.preventDefault();
	// 	});
	// });

	// const progressAnimation = progressBar.animate(
	// 	[
	// 		{ 
	// 			offset: 0.0,
	// 			strokeDashoffset: '56'
	// 		},
	// 		{
	// 			offset: 0.9,
	// 			strokeDashoffset: '39'
	// 		},
	// 		{
	// 			offset: 1.0,
	// 			strokeDashoffset: '20'
	// 		}, 
	// 	],
	// 	{
	// 		duration: 5000,
	// 		// duration: 2000,
	// 		// iterations: Infinity
	// 	}
	// );

	const paginationItems = pagination.querySelectorAll('.slider-pagination__item');

	if ( sliders === null ) return false;
	
	paginationItems[0].classList.add('is-active');

	paginationItems.forEach((item, i) =>  {

		item.addEventListener('animationiteration', () => {

			item.classList.remove('is-active');

			if ( i+1 === paginationItems.length ) {
				paginationItems[0].classList.add('is-active');
			} else {
				item.nextElementSibling.classList.add('is-active');
			}

			sliders(i);

		});
		
	});

	function sliders(i, e) {

		const sliders = document.querySelectorAll('.js-slider');

		sliders.forEach((slider) =>  {

			const slides = slider.querySelectorAll('.js-slide')
			const slideLength = slides.length;
			const current = ( i + 1 === slideLength ) ? 0 : i + 1;
			const prev = ( current - 1 === -1 ) ? slideLength-1 : current - 1;
			const next = 1;

			if ( next === 1 ) {
				slides[0].classList.remove('is-load');
			}

			slides.forEach((slide) => {
				slide.classList.remove('is-visible')
			});

			// slides[prev].classList.remove('is-visible');
			slides[current].classList.add('is-visible');
		});
	}

}

/*  carousel
---------------------------------------------------------- */
import Splide from '@splidejs/splide';
import '@splidejs/splide/css/core';

const carousel = () => {

	const els = document.querySelectorAll('.splide');

	if ( !els ) return false;

	els.forEach((el, i) => {

		new Splide(el, {
			autoWidth: true,
			gap: 'min(1.7361111111vw, 25px)',
			perPage: 3,
			rewind: true,
			arrows: true,
			pagination: false,
			// drag: false,
			autoplay: false,
			speed: 1500,
			// pauseOnHover: false,
			// updateOnMove: true,
			breakpoints: {
				768: {
					perPage: 1,
					padding: {
						right: '15.33333vw',
						left: '15.33333vw',
					},
					gap: '5.33333vw',
				},
			}
		}).mount();

	});

};

/* setFillHeight
---------------------------------------------------------- */
const setFillHeight = () => {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
};

let vw = window.innerWidth;

window.addEventListener('resize', () => {
	if (vw === window.innerWidth) {
		// 画面の横幅にサイズ変動がないので処理を終える
		return;
	}

	// 画面の横幅のサイズ変動があった時のみ高さを再計算する
	vw = window.innerWidth;
	setFillHeight();
});

/* resizeEnd
---------------------------------------------------------- */
const event = new CustomEvent('resizeend');
const currentWidth = window.innerWidth;
let timeoutID = 0;
let delay = 500;

window.addEventListener(
	'resize',
	() => {
		if (currentWidth === window.innerWidth) return false;

		clearTimeout(timeoutID);
		timeoutID = setTimeout(() => {
			window.dispatchEvent(event);
		}, delay);
	},
	false
);

/* mediaQuery
---------------------------------------------------------- */
const isPc = () => {
	return window.matchMedia('(min-width: 768px)').matches;
};

webfont();
gnavToggle();
// tab();
// dropdown();
scrolleEvents();
smoothScroll();
disableScroll();
inView();
intro();
parallax();
invert();
scrollToggleClass();
stickyUnder();
scrollPanels();
cursorFollow();
slider();
carousel();
setFillHeight();

window.addEventListener('resizeend', () => {
	stickyUnder();
});